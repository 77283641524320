// custom.scss

// Your custom variables
// $primary-color: #007bff;
// $secondary-color: #197acf;
// Add more custom variables as needed

// https://huemint.com/bootstrap-basic/#palette=f5f5f5-ffffff-212121-9e4b3b-3e4954-942991
$primary: #9e4b3b;
$secondary: #3e4954;
$success: #5b9531;
$info: #5c84a1;
$warning: #f3aa3b;
$danger: #cc3227;
$light: #f5f5f5;
$dark: #212121;
// $danger: #ff4136;

// $theme-colors: (
//   "primary": #ffb800
// );

// $btn-border-radius: 3000px;

// Import Bootstrap after custom variables
// lux
@import "~bootswatch/dist/lux/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/lux/bootswatch";

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css');



.text-notransform {
    text-transform: none;
}


.nav-item {
    margin-right: inherit;
}


.navbar-brand {
    margin-right: initial;
}


.stripe-info {
    box-sizing: border-box;
    color: rgb(109, 110, 120);
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 400;
    // height: 34.7812px;
    line-height: 17.4px;
    margin-block-end: 0px;
    margin-block-start: 8px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 8px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    // width: 672px;
    -webkit-font-smoothing: antialiased;
    letter-spacing: normal;
}


.bg-current-menu {
    background-color: rgba($primary, 0.1);
}


.navbar {
    background-color: rgba($primary, 0.1) !important;
    padding: 0.5em 0;
    --bs-navbar-color: $dark;
}


.form-group {
    margin-bottom: 0rem !important;
}


.footer {
    margin-top: auto;
    background-color: rgba($primary, 0.3);
    color: $dark;
}


.wave {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // height: auto;
    // fill: $white; /* Match the background color of the footer */
    width: 100%;
    height: 33px;
    vertical-align: super;

    .first {
        fill: $white;
    }

    .second {
        fill: rgba($white, 0.5);
    }
}


.long-words {
    overflow-wrap: break-word;
    word-wrap: break-word;
}


.product-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height:1.5em;
    min-height:1.5em;
}


.product-cover {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    background-color: $light;
}


.product-card-cover {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    background-color: $dark;
}


.product-checkout-cover {
    aspect-ratio: 16/9;
    object-fit: cover;
}


.card-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height:1.5em;
    min-height:3em;
}

.card-subtitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height:1.5em;
    min-height:4.5em;
}


b, strong {
    font-weight: 600 !important;
}


.fa-regular, .fa-solid, .fa-brands {
    // padding: 20px;
    // font-size: 'x-large';
    width: 1em;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    // border-radius: 50%;
    color: $secondary
}

.fa-solid {
    margin-right: 7px;
}


.fa:hover {
    opacity: 0.7;
}


// .fa-facebook {
//   background: #3B5998;
//   color: white;
// }


// .fa-x-twitter {
//   background: #55ACEE;
//   color: white;
// }


.edit-btn {
    position: sticky;
    top: 90%;
    height: 0;
    text-align: end;
    z-index: 10;
}


.imgEmbed {
    max-width: 100%;
    max-width: -moz-available;
    max-width: -webkit-fill-available;
    max-width: stretch;
    object-fit: contain;
}


.ql-toolbar.ql-snow {
    font-family: inherit !important;
}


.ql-container {
    font-family: inherit !important;
}


.ql-align-right {
	text-align: right;
}


.ql-align-center {
	text-align: center;
}


.ql-align-left {
	text-align: left;
}


/* Replace '.ql-toolbar' with the actual class name or selector for the Quill toolbar */
.ql-toolbar {
    position: sticky;
    top: 0;
    z-index: 1; /* Adjust the z-index value to make sure the header appears above the content */
    background-color: #f8f9fa; /* Replace this with your desired background color for the fixed header */
}


.ql-video {
    /* display: block;
    max-width: 100%; */
    width: 100%;
    aspect-ratio: 16/9;
}


.ql-color-primary {
    color: $primary;
    text-shadow: 0px 0 10px rgba($primary, 0.333);
}


.ql-color-secondary {
    color: $secondary;
    text-shadow: 0px 0 10px rgba($secondary, 0.333);
}


.ql-color-success {
    color: $success;
    text-shadow: 0px 0 10px rgba($success, 0.333);
}


.ql-color-info {
    color: $info;
    text-shadow: 0px 0 10px rgba($info, 0.333);
}


.ql-color-warning {
    color: $warning;
    text-shadow: 0px 0 10px rgba($warning, 0.333);
}


.ql-color-danger {
    color: $danger;
    text-shadow: 0px 0 10px rgba($danger, 0.333);
}


.ql-color-light {
    color: $light;
    text-shadow: 0px 0 10px rgba($light, 0.333);
}


.ql-color-dark {
    color: $dark;
    text-shadow: 0px 0 10px rgba($dark, 0.333);
}


.bm-btn {
    margin: 6px;
    border-radius: 5px;
    background-color: #ec6628;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.embed-grad {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    height: 100%;
}


.trustpilot {
    cursor: pointer;

    .title {
        font-weight: 900;
        
        .link {
            text-decoration: none;
            color: $dark;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


:export {
    primary: $primary;
    secondary: $secondary;
    success: $success;
    info: $info;
    warning: $warning;
    danger: $danger;
    light: $light;
    dark: $dark;
}